<template>
  <validation-observer ref="formScope" slim>
    <b-form v-if="!submittedStatus.submitted" name="register-today" method="post" data-netlify="true"
    data-netlify-honeypot="bot-field" data-netlify-recaptcha="true" @submit.prevent="submitForm">
      <input type="hidden" name="form-name" value="register-today" />
      <input class="d-none" name="bot-field" />
      <b-card no-body>
        <b-card-body>
          <b-card-title class="mb-3 text-primary">Register today</b-card-title>
          <b-card-sub-title class="mb-4">To register for the Scheme as a user or a driver simply complete
            this form (all fields are required)</b-card-sub-title>

          <b-form-group label="Your Name" for="name-input" description="The name that you wish us to know you by.">
            <validation-provider rules="required" v-slot="{ errors }">
              <b-form-input v-model="form.name" name="name" id="name-input" placeholder="Enter your Name" />
              <span class="text-danger">{{ errors[0] }}</span>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Intentions" id="intents-group" description="How do you intend to use the scheme?">
            <validation-provider rules="required" v-slot="{ errors }">
              <b-form-checkbox-group v-model="purpose" name="purpose" :options="options"
                aria-describedby="intentions-group" />
              <span class="text-danger">{{ errors[0] }}</span>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Your address" for="address-input"
            description="The address where you live (please include postcode).">
            <validation-provider rules="required" v-slot="{ errors }">
              <b-form-textarea v-model="form.address" id="address-input" name="address" placeholder="Enter your address"
                rows="3" max-rows="6" />
              <span class="text-danger">{{ errors[0] }}</span>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Telephone" for="telephone-input"
            description="The telephone number we should contact you on.">
            <validation-provider rules="required" v-slot="{ errors }">
              <b-form-input v-model="form.telephone" name="telephone" id="telephone-input" type="tel"
                placeholder="Enter your telephone number" />
              <span class="text-danger">{{ errors[0] }}</span>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Email Address" for="email-input"
            description="The email address we should contact you on.">
            <validation-provider rules="required|email" v-slot="{ errors }">
              <b-form-input v-model="form.email" name="email" id="email-input" type="email"
                placeholder="Enter your email address" />
              <span class="text-danger">{{ errors[0] }}</span>
            </validation-provider>
          </b-form-group>
          <hr />
          <h6 class="mt-4 mb-2">In an emergency who should we contact:</h6>
          <b-form-group label="Name" for="emergency-name"
            description="What is the name of the person we should contact in an emergency?">
            <validation-provider rules="required" v-slot="{ errors }">
              <b-form-input v-model="form.emergencyName" name="emergencyName" id="emergency-name"
                placeholder="Enter the emergency contacts name" />
              <span class="text-danger">{{ errors[0] }}</span>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Relationship" for="emergency-relationship"
            description="What relationship do you have with the person we should contact in an emergency?">
            <validation-provider rules="required" v-slot="{ errors }">
              <b-form-input v-model="form.emergencyRelationship" name="emergencyRelationship"
                id="emergency-relationship" placeholder="Enter the emergency contacts relationship to you" />
              <span class="text-danger">{{ errors[0] }}</span>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Telephone" id="emergency-telephone"
            description="What telephone number should we contact when trying to reach your emergency contact?">
            <validation-provider rules="required" v-slot="{ errors }">
              <b-form-input v-model="form.emergencyTelephone" id="emergency-telephone" name="emergencyTelephone"
                type="tel" placeholder="Enter the emergency contacts telephone number" />
              <span class="text-danger">{{ errors[0] }}</span>
            </validation-provider>
          </b-form-group>
          <div class="g-recaptcha" data-sitekey="6LcqMR8qAAAAAIQvYlYW4LS1ROa9VTCmi68dBoJB"></div>
          <div v-if="recaptcha.isMissing" class="text-danger">{{ recaptcha.error }}</div>
        </b-card-body>
        <b-card-footer>
          <b-btn id="submit" class="float-right" variant="primary" type="submit">Submit</b-btn>
        </b-card-footer>
      </b-card>
    </b-form>
    <submission-status :submitted="submittedStatus.submitted" :success="submittedStatus.success" />
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";

import { required, email } from "vee-validate/dist/rules";
extend("required", {
  ...required,
  message: "This field is required.",
});
extend("email", {
  ...email,
  message: "The text you entered is not a valid email.",
});
import { post } from "axios";
import SubmissionStatus from "./SubmissionStatus.vue";
export default {
  name: "RegistrationForm",
  components: {
    ValidationProvider,
    ValidationObserver,
    SubmissionStatus,
  },
  data: () => ({
    options: [
      {
        text: "I would like to use the scheme",
        value: "I would like to use the scheme",
      },
      {
        text: "I am interested in driving for the scheme",
        value: "I am interested in driving for the scheme",
      },
    ],
    submittedStatus: {
      submitted: false,
      success: false,
    },
    purpose: [],
    form: {
      name: null,
      purpose: null,
      address: null,
      telephone: null,
      email: null,
      emergencyName: null,
      emergencyRelationship: null,
      emergencyTelephone: null,
    },
    recaptcha: {
      isMissing: false,
      error: null
    }
  }),
  methods: {
    encode(data) {
      return Object.keys(data)
        .map(
          (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
        )
        .join("&");
    },
    submitForm(event) {
      this.$refs.formScope.validate().then((isValid) => {
        if (isValid) {
          const securityResponse = window.grecaptcha.getResponse();
          if(securityResponse != null && securityResponse.trim() !== '') {
            post('/', new FormData(event.target))
              .then(() => {
                this.submittedStatus.success = true;
              })
              .catch(() => {
                this.submittedStatus.success = false;
              })
              .finally(() => {
                this.submittedStatus.submitted = true;
                this.recaptcha.isMissing = false;
                this.recaptcha.error = null;
              });
          }
          else{
            this.recaptcha.isMissing = true;
            this.recaptcha.error = 'Please complete the recaptcha.'
          }
        }
      });
    },
  },
};
</script>