<template>
  <div>
    <b-alert :show="submitted && !success" variant="danger" class="text-dark">
      <h4>Error</h4>
      <p>
        There was an error whilst submitting your request. Please contact:
        <b-link class="alert-link" href="mailto:thwvcs@gmail.com"
          >thwvcs@gmail.com</b-link
        >
      </p>
    </b-alert>
    <b-alert :show="submitted && success" variant="success" class="text-dark">
      <h4>Submitted</h4>
      <p>
        Thank you. Your form has been submitted and we will be in touch as soon
        as we can.
      </p>
    </b-alert>
  </div>
</template>
<script>
import { BAlert } from "bootstrap-vue";
export default {
  props: {
    submitted: Boolean,
    success: Boolean,
  },
  name: "SubmissionStatus",
  components: {
    BAlert,
  },
};
</script>
<style scoped>
a:link {
  color: black;
}

a:visited {
  color: black;
}

a:hover {
  color: black;
}

a:active {
  color: black;
}
</style>