<template>
  <b-container data-aos="fade-up">
    <b-img-lazy
      class="w-100"
      alt="Members of the THAW team stood by one of the vehicles."
      src="../../assets/about-thaw.jpg"
    />
    <h1 class="mt-5 mb-4 text-primary">About THAW</h1>
    <p>
      <i>
        The aim of the Thorney,
        <a href="http://www.harby-notts.org.uk/" target="_blank">Harby</a> &amp;
        Wigsley Voluntary Car Scheme (TH&amp;W) group is to improve the quality
        of life for residents in Thorney, Harby, Wigsley and surrounding areas
        eg. N &amp; S Clifton and Spalford, especially for those who don't have
        their own transport or where public transport options may not be
        appropriate or available.
      </i>
    </p>
    <p>
      The scheme is open to everyone young or old. We provide a community
      transport service to enable residents to access health related services,
      shopping amenities, leisure and social activities during the day or
      evenings. Journeys will be charged at 45p per mile though a minimum charge
      of £2 (per vehicle) will apply for all trips.
    </p>
    Every attempt will be made to meet requests but this will be dependent on
    driver availability. Volunteers may opt to help out on an occasional basis
    but the more volunteers that can be recruited will be to the overall benefit
    of us all. None of us know when our car may break down or you may need
    transport in an emergency so it is well worth considering registering with
    this Scheme.
    <p>
      To access the Scheme either as a
      <b-link to="/passengers">passenger</b-link> or a
      <b-link to="/drivers">driver</b-link> you are required to register as a
      member of TH&amp;W Voluntary Car Scheme (see below). There is no charge
      for this and all details will be held confidentially and only passed on to
      a driver when a journey is being undertaken.
    </p>
    <p>
      The TH&amp;W Voluntary Car Scheme is supported by the
      <a href="https://www.biglotteryfund.org.uk/about-big" target="_blank"
        >National Lottery</a
      >.
    </p>
    <registration-form />
  </b-container>
</template>

<script>
import RegistrationForm from "../shared/RegistrationForm.vue";
export default {
  name: "AboutThaw",
  components: {
    RegistrationForm
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
