var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"formScope",attrs:{"slim":""}},[(!_vm.submittedStatus.submitted)?_c('b-form',{attrs:{"name":"register-today","method":"post","data-netlify":"true","data-netlify-honeypot":"bot-field","data-netlify-recaptcha":"true"},on:{"submit":function($event){$event.preventDefault();return _vm.submitForm($event)}}},[_c('input',{attrs:{"type":"hidden","name":"form-name","value":"register-today"}}),_c('input',{staticClass:"d-none",attrs:{"name":"bot-field"}}),_c('b-card',{attrs:{"no-body":""}},[_c('b-card-body',[_c('b-card-title',{staticClass:"mb-3 text-primary"},[_vm._v("Register today")]),_c('b-card-sub-title',{staticClass:"mb-4"},[_vm._v("To register for the Scheme as a user or a driver simply complete this form (all fields are required)")]),_c('b-form-group',{attrs:{"label":"Your Name","for":"name-input","description":"The name that you wish us to know you by."}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":"name","id":"name-input","placeholder":"Enter your Name"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3703775749)})],1),_c('b-form-group',{attrs:{"label":"Intentions","id":"intents-group","description":"How do you intend to use the scheme?"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox-group',{attrs:{"name":"purpose","options":_vm.options,"aria-describedby":"intentions-group"},model:{value:(_vm.purpose),callback:function ($$v) {_vm.purpose=$$v},expression:"purpose"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1618380019)})],1),_c('b-form-group',{attrs:{"label":"Your address","for":"address-input","description":"The address where you live (please include postcode)."}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"address-input","name":"address","placeholder":"Enter your address","rows":"3","max-rows":"6"},model:{value:(_vm.form.address),callback:function ($$v) {_vm.$set(_vm.form, "address", $$v)},expression:"form.address"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1277171365)})],1),_c('b-form-group',{attrs:{"label":"Telephone","for":"telephone-input","description":"The telephone number we should contact you on."}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":"telephone","id":"telephone-input","type":"tel","placeholder":"Enter your telephone number"},model:{value:(_vm.form.telephone),callback:function ($$v) {_vm.$set(_vm.form, "telephone", $$v)},expression:"form.telephone"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1766028213)})],1),_c('b-form-group',{attrs:{"label":"Email Address","for":"email-input","description":"The email address we should contact you on."}},[_c('validation-provider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":"email","id":"email-input","type":"email","placeholder":"Enter your email address"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1688352497)})],1),_c('hr'),_c('h6',{staticClass:"mt-4 mb-2"},[_vm._v("In an emergency who should we contact:")]),_c('b-form-group',{attrs:{"label":"Name","for":"emergency-name","description":"What is the name of the person we should contact in an emergency?"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":"emergencyName","id":"emergency-name","placeholder":"Enter the emergency contacts name"},model:{value:(_vm.form.emergencyName),callback:function ($$v) {_vm.$set(_vm.form, "emergencyName", $$v)},expression:"form.emergencyName"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4105817640)})],1),_c('b-form-group',{attrs:{"label":"Relationship","for":"emergency-relationship","description":"What relationship do you have with the person we should contact in an emergency?"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":"emergencyRelationship","id":"emergency-relationship","placeholder":"Enter the emergency contacts relationship to you"},model:{value:(_vm.form.emergencyRelationship),callback:function ($$v) {_vm.$set(_vm.form, "emergencyRelationship", $$v)},expression:"form.emergencyRelationship"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3123589104)})],1),_c('b-form-group',{attrs:{"label":"Telephone","id":"emergency-telephone","description":"What telephone number should we contact when trying to reach your emergency contact?"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"emergency-telephone","name":"emergencyTelephone","type":"tel","placeholder":"Enter the emergency contacts telephone number"},model:{value:(_vm.form.emergencyTelephone),callback:function ($$v) {_vm.$set(_vm.form, "emergencyTelephone", $$v)},expression:"form.emergencyTelephone"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3316193784)})],1),_c('div',{staticClass:"g-recaptcha",attrs:{"data-sitekey":"6LcqMR8qAAAAAIQvYlYW4LS1ROa9VTCmi68dBoJB"}}),(_vm.recaptcha.isMissing)?_c('div',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.recaptcha.error))]):_vm._e()],1),_c('b-card-footer',[_c('b-btn',{staticClass:"float-right",attrs:{"id":"submit","variant":"primary","type":"submit"}},[_vm._v("Submit")])],1)],1)],1):_vm._e(),_c('submission-status',{attrs:{"submitted":_vm.submittedStatus.submitted,"success":_vm.submittedStatus.success}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }